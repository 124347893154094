import React from 'react'
import Privacy from '../modules/Privacy'

const PrivacyTemplate = () => {
  return (
    <main>
      <Privacy />
    </main>
  )
}

export default PrivacyTemplate
